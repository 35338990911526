import { configureStore, combineReducers } from "@reduxjs/toolkit";

/** libs */
import { persistReducer, persistStore } from "redux-persist";
import thunk from "redux-thunk";
import createWebStorage from "redux-persist/lib/storage/createWebStorage";

/** reducers */
import AppReducer from "./app";
import UserReducer from "./user";
import ProjectsReducer from "./projects";

const createNoopStorage = () => {
    return {
        getItem(_key) {
            return Promise.resolve(null);
        },
        setItem(_key, value) {
            return Promise.resolve(value);
        },
        removeItem(_key) {
            return Promise.resolve();
        },
    };
};
const storage = typeof window !== "undefined" ? createWebStorage("local") : createNoopStorage();

const persistConfig = {
    key: "ina",
    storage,
};

const appReducer = combineReducers({
    App: AppReducer,
    User: UserReducer,
    Projects: ProjectsReducer,
});

const rootReducer = (state, action) => {
    if (action.type === 'RESET_STORE') {
        storage.removeItem(`persist:${persistConfig.key}`);

        return appReducer(undefined, action);
    }
    return appReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: [thunk],
    devTools: process.env.NODE_ENV !== "production",
});

export const persistor = persistStore(store);
