import { createSlice } from "@reduxjs/toolkit";

export const AppSlice = createSlice({
    name: "App",
    initialState: {
        applicationTools: false,
        themeMode: "light",
        themePalette: "Application",
        scroll: 0,
    },
    reducers: {
        setApplicationTools: (state, action) => {
            state.applicationTools = action.payload;
        },
        setThemeMode: (state, action) => {
            state.themeMode = action.payload;
        },
        setThemePalette: (state, action) => {
            state.themePalette = action.payload;
        },
        setScroll: (state, action) => {
            state.scroll = action.payload;
        },
    },
});

export const { setApplicationTools, setThemeMode, setThemePalette, setScroll } = AppSlice.actions;

export const getApplicationTools = (state) => state.App.applicationTools;
export const getThemeMode = (state) => state.App.themeMode;
export const getThemePalette = (state) => state.App.themePalette;
export const getScroll = (state) => state.App.scroll;

export default AppSlice.reducer;
