export const BackgroundImage = `<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 2000 1500'>
<rect fill='#121212' width='2000' height='1500'/>
<defs>
    <rect stroke='#121212' stroke-width='.5' width='1' height='1' id='s'/>
    <pattern id='a' width='3' height='3' patternUnits='userSpaceOnUse' patternTransform='scale(50) translate(-980 -735)'>
        <use fill='#282828' href='#s' y='2'/>
        <use fill='#282828' href='#s' x='1' y='2'/>
        <use fill='#363636' href='#s' x='2' y='2'/>
        <use fill='#363636' href='#s'/>
        <use fill='#414141' href='#s' x='2'/>
        <use fill='#414141' href='#s' x='1' y='1'/>
    </pattern>
    <pattern id='b' width='7' height='11' patternUnits='userSpaceOnUse' patternTransform='scale(50) translate(-980 -735)'>
        <g fill='#4a4a4a'>
            <use href='#s'/>
            <use href='#s' y='5' />
            <use href='#s' x='1' y='10'/>
            <use href='#s' x='2' y='1'/>
            <use href='#s' x='2' y='4'/>
            <use href='#s' x='3' y='8'/>
            <use href='#s' x='4' y='3'/>
            <use href='#s' x='4' y='7'/>
            <use href='#s' x='5' y='2'/>
            <use href='#s' x='5' y='6'/>
            <use href='#s' x='6' y='9'/>
        </g>
    </pattern>
    <pattern id='h' width='5' height='13' patternUnits='userSpaceOnUse' patternTransform='scale(50) translate(-980 -735)'>
        <g fill='#4a4a4a'>
            <use href='#s' y='5'/>
            <use href='#s' y='8'/>
            <use href='#s' x='1' y='1'/>
            <use href='#s' x='1' y='9'/>
            <use href='#s' x='1' y='12'/>
            <use href='#s' x='2'/>
            <use href='#s' x='2' y='4'/>
            <use href='#s' x='3' y='2'/>
            <use href='#s' x='3' y='6'/>
            <use href='#s' x='3' y='11'/>
            <use href='#s' x='4' y='3'/>
            <use href='#s' x='4' y='7'/>
            <use href='#s' x='4' y='10'/>
        </g>
    </pattern>
    <pattern id='c' width='17' height='13' patternUnits='userSpaceOnUse' patternTransform='scale(50) translate(-980 -735)'>
        <g fill='#525252'>
            <use href='#s' y='11'/>
            <use href='#s' x='2' y='9'/>
            <use href='#s' x='5' y='12'/>
            <use href='#s' x='9' y='4'/>
            <use href='#s' x='12' y='1'/>
            <use href='#s' x='16' y='6'/>
        </g>
    </pattern>
    <pattern id='d' width='19' height='17' patternUnits='userSpaceOnUse' patternTransform='scale(50) translate(-980 -735)'>
        <g fill='#121212'>
            <use href='#s' y='9'/>
            <use href='#s' x='16' y='5'/>
            <use href='#s' x='14' y='2'/>
            <use href='#s' x='11' y='11'/>
            <use href='#s' x='6' y='14'/>
        </g>
        <g fill='#5a5a5a'>
            <use href='#s' x='3' y='13'/>
            <use href='#s' x='9' y='7'/>
            <use href='#s' x='13' y='10'/>
            <use href='#s' x='15' y='4'/>
            <use href='#s' x='18' y='1'/>
        </g>
    </pattern>
    <pattern id='e' width='47' height='53' patternUnits='userSpaceOnUse' patternTransform='scale(50) translate(-980 -735)'>
        <g fill='{{SECONDARY_COLOR}}'>
            <use href='#s' x='2' y='5'/>
            <use href='#s' x='16' y='38'/>
            <use href='#s' x='46' y='42'/>
            <use href='#s' x='29' y='20'/>
        </g>
    </pattern>
    <pattern id='f' width='59' height='71' patternUnits='userSpaceOnUse' patternTransform='scale(50) translate(-980 -735)'>
        <g fill='{{SECONDARY_COLOR}}'>
            <use href='#s' x='33' y='13'/>
            <use href='#s' x='27' y='54'/>
            <use href='#s' x='55' y='55'/>
        </g>
    </pattern>
    <pattern id='g' width='139' height='97' patternUnits='userSpaceOnUse' patternTransform='scale(50) translate(-980 -735)'>
        <g fill='{{SECONDARY_COLOR}}'>
            <use href='#s' x='11' y='8'/>
            <use href='#s' x='51' y='13'/>
            <use href='#s' x='17' y='73'/>
            <use href='#s' x='99' y='57'/>
        </g>
    </pattern>
</defs>
<rect fill='url(#a)' width='100%' height='100%'/>
<rect fill='url(#b)' width='100%' height='100%'/>
<rect fill='url(#h)' width='100%' height='100%'/>
<rect fill='url(#c)' width='100%' height='100%'/>
<rect fill='url(#d)' width='100%' height='100%'/>
<rect fill='url(#e)' width='100%' height='100%'/>
<rect fill='url(#f)' width='100%' height='100%'/>
<rect fill='url(#g)' width='100%' height='100%'/>
</svg>`