import { createSlice } from "@reduxjs/toolkit";

export const UserSlice = createSlice({
    name: "User",
    initialState: {
        user: false,
    },
    reducers: {
        setUser: (state, action) => {
            state.user = action.payload;
        },
    },
});

export const { setUser } = UserSlice.actions;

export const getUser = (state) => state.User.user;

export default UserSlice.reducer;
