import React from "react";

/** mui */
// import { MuiThemeProvider } from "@material-ui/core";
import { ThemeProvider } from "@mui/material/styles";

/** redux */
import { useSelector } from "react-redux";
import { getThemePalette, getThemeMode } from "@/src/store/app";

/** helpers */
import { getThemeByName } from "..";

export const ThemeContext = React.createContext((themePalette) => {});

const AppThemeProvider = (props) => {
    // State to hold the selected theme palette & mode
    const themePalette = useSelector(getThemePalette);
    const themeMode = useSelector(getThemeMode);

    // Retrieve the theme object by theme palette & mode
    const theme = getThemeByName(themePalette, themeMode);    

    return (
        <ThemeContext.Provider value={getThemePalette}>
            <ThemeProvider theme={theme}>{props.children}</ThemeProvider>;
        </ThemeContext.Provider>
    );
};

export default AppThemeProvider;
