import { createSlice } from "@reduxjs/toolkit";

export const ProjectsSlice = createSlice({
    name: "Projects",
    initialState: {
        view: "card",
        projects: null,
        gitlabJson: null,
        originalJson: null,
        currentJson: null,
        isChanged: null,
    },
    reducers: {
        setView: (state, action) => {
            state.view = action.payload;
        },
        setProjects: (state, action) => {
            state.projects = action.payload;
        },
        setGitlabJson: (state, action) => {
            state.gitlabJson = action.payload;
        },
        setOriginalJson: (state, action) => {
            state.originalJson = action.payload;
        },
        setCurrentJson: (state, action) => {
            state.currentJson = action.payload;
        },
        setIsChanged: (state, action) => {
            state.isChanged = action.payload;
        },
    },
});

export const { setView, setProjects, setGitlabJson, setOriginalJson, setCurrentJson, setIsChanged } =
    ProjectsSlice.actions;

export const getView = (state) => state.Projects.view;
export const getProjects = (state) => state.Projects.projects;
export const getGitlabJson = (state) => state.Projects.gitlabJson;
export const getOriginalJson = (state) => state.Projects.originalJson;
export const getCurrentJson = (state) => state.Projects.currentJson;
export const getIsChanged = (state) => state.Projects.isChanged;

export default ProjectsSlice.reducer;
