import "@/config/MuiClassNameSetup";
/** nextjs */
import { SessionProvider } from "next-auth/react";
import dynamic from "next/dynamic";
import Head from "next/head";

/** mui */
import CssBaseline from "@mui/material/CssBaseline";
import { StyledEngineProvider } from "@mui/material/styles";
import { StylesProvider, createGenerateClassName } from "@mui/styles";

/** emotion */
import { CacheProvider } from "@emotion/react";

/** Redux */
import { persistor, store } from "@/src/store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

/** helpers */
import AppThemeProvider from "@/config/theme/AppThemeProvider";
import createEmotionCache from "@/config/createEmotionCache";
import "@/styles/globals.css";

const clientSideEmotionCache = createEmotionCache();

const generateClassName = () =>
    createGenerateClassName({
        seed: "css", // + shasum.digest("hex").substr(0, 13),
    });

/** ui */
const AppScroll = dynamic(() => import("@/src/layouts/AppScroll"));
const AppTools = dynamic(() => import("@/src/shared/ApplicationTools"));
const FullpagePreloader = dynamic(() => import("@/src/shared/FullpagePreloader"));

export default function App(props) {
    const {
        Component,
        emotionCache = clientSideEmotionCache,
        pageProps: { session, ...pageProps },
    } = props;

    return (
        <CacheProvider value={emotionCache}>
            <SessionProvider session={session}>
                <Head>
                    <meta name="viewport" content="initial-scale=1, width=device-width" />
                </Head>
                <StyledEngineProvider injectFirst>
                    <StylesProvider generateClassName={generateClassName()}>
                        {/* <ThemeProvider theme={theme}> */}
                        <Provider store={store}>
                            <AppThemeProvider>
                                <PersistGate loading={null} persistor={persistor}>
                                    <>
                                        <AppScroll>
                                            <CssBaseline />
                                            <AppTools />
                                            <Component {...pageProps} />
                                        </AppScroll>
                                    </>
                                </PersistGate>
                            </AppThemeProvider>
                        </Provider>
                        {/* </ThemeProvider> */}
                    </StylesProvider>
                </StyledEngineProvider>
            </SessionProvider>
        </CacheProvider>
    );
}
