import { alpha, createTheme } from "@mui/material/styles";
import { red } from "@mui/material/colors";

import { BackgroundImage as LightBG } from "../assets/lightBG";
import { BackgroundImage as DarkBG } from "../assets/darkBG";
import { Logo, TlumApp } from "../assets/logo";
import { convertSVG } from "../utilites";
import palettesConfig from "./palettes.config.json";

export const makeThemeByPaletteAndMode = ({ paletteName, mode }) => {
    const _palette = palettesConfig?.find((c) => c.name === paletteName);
    // Create a theme instance.
    const defaultTheme = createTheme({
        palette: {
            primary: {
                main: _palette.primary,
            },
            secondary: {
                main: _palette.secondary,
            },
            error: {
                main: red.A400,
            },
            name: paletteName,
            mode,
        },
    });

    const BackgroundImage = mode === "light" ? LightBG : DarkBG;

    const pageBgImage = convertSVG({
        raw: BackgroundImage.replace(/{{SECONDARY_COLOR}}/g, defaultTheme.palette.secondary.main),
    });

    // const logoImage = convertSVG({
    //     raw: Logo.replace(/{{SECONDARY_COLOR}}/g, defaultTheme.palette.secondary.main).replace(
    //         /{{PRIMARY_COLOR}}/g,
    //         defaultTheme.palette.primary.main
    //     ),
    //     dataOnly: true,
    // });
    const logoImage = convertSVG({
        raw: TlumApp.replace(/{{SECONDARY_COLOR}}/g, defaultTheme.palette.secondary.main).replace(
            /{{PRIMARY_COLOR}}/g,
            defaultTheme.palette.primary.main
        ),
        dataOnly: true,
    });

    return {
        ...defaultTheme,
        components: {
            MuiCssBaseline: {
                styleOverrides: {
                    html: {
                        backgroundColor: defaultTheme.palette.background.paper,
                        overflow: "hidden",
                    },
                    body: {
                        backgroundColor: defaultTheme.palette.background.paper,
                        color: "rgba(58, 53, 65, 0.6)",
                        backgroundImage: pageBgImage,
                        backgroundAttachment: "fixed",
                        backgroundSize: "cover",
                    },
                },
            },
            MuiTooltip: {
                styleOverrides: {
                    tooltip: {
                        backgroundColor: defaultTheme.palette.background.paper,
                        // color: defaultTheme.palette.primary.main,
                        color: defaultTheme.palette.text.primary,
                        border: `1px solid ${alpha(defaultTheme.palette.text.primary, 0.125)}`,
                        boxShadow: defaultTheme.shadows[1],
                        fontSize: "0.9rem",
                        padding: defaultTheme.spacing(1, 2),
                        borderRadius: defaultTheme.spacing(2),
                    },
                    arrow: {},
                    popper: {},
                },
            },
            MuiButton: {
                styleOverrides: {
                    root: {
                        textTransform: "capitalize",
                        borderRadius: defaultTheme.spacing(3),
                    },
                },
            },
        },
        assets: {
            pageBgImage,
            logoImage,
        },
    };
};
