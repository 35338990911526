import palettesConfig from "./palettes/palettes.config.json";
import { makeThemeByPaletteAndMode } from "./palettes";

export const getThemeByName = (themePalette, themeMode) => themeMap()[themeMode][themePalette];

export const themeMap = () => {
    const _palettesNames = palettesConfig.map((c) => c.name) || [];

    let _map = { light: {}, dark: {} };
    _palettesNames.forEach((paletteName) => {
        _map.light[paletteName] = makeThemeByPaletteAndMode({ paletteName, mode: "light" });
        _map.dark[paletteName] = makeThemeByPaletteAndMode({ paletteName, mode: "dark" });
    });
    return _map;
};

export const getThemesPalettes = () => {
    const _theme = { light: [], dark: [] };

    ["light", "dark"].forEach((mode) => {
        Object.keys(themeMap()[mode])?.forEach((key) => {
            _theme[mode].push({
                mode,
                name: key,
                primary: themeMap()[mode][key]?.palette.primary.main,
                secondary: themeMap()[mode][key]?.palette.secondary.main,
                palette: themeMap()[mode][key]?.palette,
            });
        });
    });

    return _theme;
};

// export { theme } from "./palettes/default_light";
